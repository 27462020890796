@media only screen and (min-width: 600px) and (max-width: 1199px) {
  .MC-App {
    .mcHeader {
      height: 6vmax;
      justify-content: space-around;

      .logoSection {
        flex-basis: 50%;

        .logo {
          width: 5vmax;
          height: 5vmax;
          margin: 0 1rem;
        }

        .brand-title {
          width: 15rem;
          transform: translateX(1rem);
        }
      }

      .actionSection {
        .categories,
        .profiles {
          font-size: 1.25rem;
        }

        .categories {
          .title {
            transform: translateX(-0.5em);
          }

          ul li:hover ul,
          ul li:focus ul,
          ul li:focus-within ul,
          ul li ul:hover,
          ul li ul:focus {
            left: -95%;
          }
        }
      }
    }

    .mcBody {
      min-height: calc(100vh - 6vmax);
      transform: translateY(6vmax); //w.r.to header height

      .fetchingCurrentUserDetails,
      .fetchingImages .spinner {
        width: 60px !important;
        height: 60px !important;
      }

      .upload-form {
        top: 20% !important;
      }

      .banner {
        max-width: 70%;
      }
    }

    .swStatus {
      margin: 0px 10px !important;

      button {
        margin: 0px 10px !important;
      }
    }

    .mcFooter {
      height: 3rem;
      margin-top: 6vmax;
    }
  }
}
