@media print {
  .mcHeader,
  .mcFooter {
    display: none !important;
  }
  html {
    print-color-adjust: exact;
    background-color: var(--secondary-color) !important;
  }
  .mcBody .aboutme {
    margin: 0 !important;
    border-radius: 0 !important;
    background-color: var(--secondary-color) !important;
  }
}
