.mcModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: auto;
  overscroll-behavior: contain;

  .mcModalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .mcModalBody {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }
  }

  .close {
    position: absolute;
    &:hover,
    &:focus {
      transform: rotate(360deg);
      transition-duration: 500ms;
    }
  }
}
