@import './styles/fonts.scss';
@import './styles/common.scss';
@import './styles/screens/handhelds.scss';
@import './styles/screens/tablets.scss';
@import './styles/screens/laptops.scss';
@import './styles/screens/desktops.scss';
@import './styles/print.scss';

.MC-App {
  font-weight: 400;
  overflow: hidden;

  .mcHeader {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: var(--secondary-color);
    color: var(--secondary-color-inverted);

    .logoSection {
      & > * {
        cursor: pointer;
      }

      .logo {
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(-2px);
      }

      .brand-title {
        height: 100%;
      }
    }

    .actionSection {
      justify-content: space-around;
      flex-basis: 40%;

      & > * {
        cursor: pointer;
      }

      .profiles {
        position: relative;
        display: flex !important;
        margin: 0px 5px;

        .profileMenu {
          width: 1.75rem;
          height: 1.75rem;
        }

        ul {
          right: 0;

          li {
            position: relative;
            font-size: 0.75em;
            padding: 5px 0px 5px 10px;

            a {
              display: block;
              text-decoration: none;
            }

            &:hover,
            &:focus,
            &:focus-within {
              cursor: pointer;
              outline: none;
              background-color: var(--primary-color);
            }
          }
        }

        &:hover > ul,
        &:focus > ul,
        &:focus-within > ul,
        ul:hover,
        ul:focus {
          background-color: var(--secondary-color);
          z-index: 1;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .mcBody {
    position: relative;
    color: var(--secondary-color);
    background-color: var(--secondary-color-inverted);
    overflow: auto;

    .loadingContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .spinner {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 40%;
      }
    }

    .descriptionContainer {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0.5em;
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--secondary-color-inverted);
      word-break: break-word;
      mix-blend-mode: luminosity;
    }

    .highlight {
      font-size: 1.1em;
      font-weight: 600;
    }
  }

  .mcFooter {
    justify-content: space-around;
    background-color: var(--secondary-color);
    color: var(--secondary-color-inverted);
    font-size: 1rem;

    .column {
      display: flex;
      align-items: center;
    }

    .row {
      a {
        display: inline-flex;
        .linkedin,
        .github {
          height: 1.25rem;
          width: 1.25rem;
          background-repeat: no-repeat;
          cursor: pointer;
        }

        .linkedin {
          display: inline-block;
          background-image: url('./assets/linkedin.svg');
          background-position: center;
          margin-left: 0.5rem;
        }

        .github {
          display: inline-block;
          background-image: url('./assets/github.svg');
          margin: 0 1rem;
        }
      }
    }
  }
}
