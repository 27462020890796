@media only screen and (min-width: 1900px) {
  .MC-App {
    --desktop-header-height: 4vmax;
    .mcHeader {
      height: var(--desktop-header-height);
      justify-content: space-between;

      .logoSection {
        flex-basis: 30%;

        .logo {
          width: var(--desktop-header-height);
          height: var(--desktop-header-height);
          margin-left: 7rem;
        }

        .brand-title {
          transform: translateX(3rem);
        }
      }

      .actionSection {
        & > * {
          align-items: center;
          cursor: pointer;
        }

        .categories,
        .profiles {
          font-size: 1.75rem;
        }

        .profiles ul {
          width: 180px !important;
        }
      }
    }

    .mcBody {
      min-height: calc(100vh - 5vmax);
      transform: translateY(
        var(--desktop-header-height)
      ); //w.r.to header height

      .banner {
        max-width: 50%;
      }
    }

    .swStatus {
      .updateAvailable button {
        font-size: 1em !important;
      }
    }

    .mcFooter {
      height: 5vmin;
      margin-top: 4vmax;
    }
  }
}
